.hero-container {
  text-align: center;
  background-color: var(--text-color);
  border-radius: 5px;
}

.hero-title {
  font-size: 2.8rem;
  color: var(--primary-color);
  position: relative;
}

.hero-title::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0) 90%);
  opacity: 0.7;
  pointer-events: none;
  border-radius: 5px;
}
